@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

body {
  margin: 0;
  font-family: "DM Sans", system-ui;
  font-optical-sizing: auto;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'FoundersGroteskRegular';
  font-weight: 400;
  src: url(/src/fonts/foundersgrotesk/FoundersGrotesk-Regular.otf);
}

.doc-TITLE {
  font-size: 27px;
  font-weight: bold;
  line-height: 28px;
  margin: 16px 0px
}

.doc-HEADING_1 {
  font-size: 25px;
  line-height: 27px;
  margin: 16px 0px
}

.doc-HEADING_2 {
  font-size: 24px;
  line-height: 25px;
  margin: 14px 0px
}

.doc-HEADING_3 {
  font-size: 23px;
  line-height: 24px;
  margin: 14px 0px
}

.doc-HEADING_4 {
  font-size: 22px;
  line-height: 23px;
  margin: 12px 0px
}

.doc-HEADING_5 {
  font-size: 21px;
  line-height: 22px;
  margin: 12px 0px
}

.doc-HEADING_6 {
  font-size: 20px;
  line-height: 22px;
  margin: 10px 0px
}

.doc-NORMAL_TEXT {
  font-size: 18px;
  line-height: 22px;
  margin: 3px 0px
}

.doc-CENTER {
  text-align: center
}

.explore-more-popup {
  width: 992px !important;
}

.ask-to-draft-modal {
  width: 992px !important;
  height: 683px;
}

.account-layout {
  height: 100%;
  /* background: var(--Gradeint, linear-gradient(180deg, #5e59ff 0%, #322f95 100%)); */
  padding: 38px 218px 0 218px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}

.account-container {
  overflow-y: scroll !important;
}

.account-layout__content__item__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 16px; */
  /* border-bottom: 1px solid var(--Offwhite, #f5f5f5); */
}

.account-layout__content__item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-height: 135px; */
  padding: 17px 33px;
}

.account-layout__content__item__label {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.account-layout__content__item__value {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.account-layout__content__item__value__text {
  color: var(--Purple---Final, #5e59ff);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.account-layout__content__item__edit-btn {
  display: flex;
  width: 169px;
  height: 49px;
  padding: 14px 51px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--Light-Grey, #cacaca);
  background: var(--White, #fff);
  color: var(--Black---Final, #0d0d0d);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.account-layout__content__edit-container {
  display: flex;
  /* align-items: center; */
  /* gap: 16px; */
  justify-content: flex-start;
  margin: 0px 33px 0px 33px;
}

.account-layout__content__edit-container__input {
  display: flex;
  width: 500px;
  padding: 15px 25px;
  align-items: center;
  border-radius: 9px;
  background: #eaeaea;
  border: transparent;
}

.account-layout__content__edit-container__input.password {
  max-width: 200px !important;
}

.account-layout__content__edit-save {
  display: flex;
  width: 169px;
  height: 100% !important;
  padding: 14px 51px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--Purple---Final, #5e59ff);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 54px !important;
}

.account-layout__additional-settings-btn {
  color: var(--Dark-Grey, #929292);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 22px;
  margin-bottom: 22px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  padding: 0;
}

.account-layout__additional-settings-btn:hover {
  color: var(--Dark-Grey, #929292) !important;
  background-color: white !important;
}

@media only screen and (max-width: 768px) {
  .account-layout {
    padding: 20px 10px 0 10px;
  }

  .account-layout__content__item {
    flex-direction: column;
    gap: 20px;
  }

  .account-layout__content__item__info {
    width: 100%;
  }

  .account-layout__content__item__edit-btn {
    width: 100%;
  }

  .account-layout__content__edit-container {
    width: -webkit-fill-available;
    flex-direction: column;
    overflow: hidden;
    /* overflow-x: scroll !important; */
  }

  .account-layout__content__edit-container__input {
    width: 100%;
  }

  .account-layout__content__edit-container__input.password {
    width: 100% !important;
    max-width: 100% !important;
  }

  .account-layout__content__edit-save {
    width: 100%;
  }
}